import { FindManyDisconnectedOptionVariables } from 'queries/disconnected-option'

export interface QueryFilters {
  feature?: {
    id?: string
    identifier?: string
  }
  includeCustom?: boolean
  search?: string
  sortBy?: 'identifier' | 'description'
  sortDirection?: 'ascending' | 'descending'
  take?: number
}

export const getQueryVariables = ({
  includeCustom,
  feature,
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyDisconnectedOptionVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'identifier': {
        return {
          identifier: { sort: sortDirection === 'descending' ? 'desc' : 'asc' },
        }
      }
      case 'description': {
        return {
          description: {
            sort: sortDirection === 'descending' ? 'desc' : 'asc',
          },
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyDisconnectedOptionVariables = {
    orderBy,
    take,
    where: {
      optionIdentifier: {
        not: {
          equals: 'custom',
        },
      },
    },
  }
  if (includeCustom) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        optionIdentifier: undefined,
      },
    }
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        description: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (feature) {
    return {
      ...variables,
      where: {
        ...variables.where,
        ...(feature?.id
          ? {
              feature: {
                is: {
                  id: {
                    equals: feature.id,
                  },
                },
              },
            }
          : null),
        ...(feature?.identifier
          ? {
              featureIdentifier: {
                equals: feature.identifier,
              },
            }
          : null),
      },
    }
  }
  return variables
}
