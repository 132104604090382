import { useQuery } from '@apollo/client'

import {
  FIND_MANY_PROJECT_DASHBOARD,
  FindManyProjectPayload,
  FindManyProjectVariables,
} from 'queries/project'
import { ProjectStaffRole } from 'types/project-staff'

const useProjectMany = (
  userId: string,
  projectStaffRole?: ProjectStaffRole,
) => {
  const {
    data: { projects } = { projects: [] },
    loading,
    refetch,
  } = useQuery<FindManyProjectPayload, FindManyProjectVariables>(
    FIND_MANY_PROJECT_DASHBOARD,
    {
      variables: {
        orderBy: { createdAt: { sort: 'desc' } },
        where: {
          archivedAt: null,
          staff: {
            some: {
              staffMemberId: {
                equals: userId,
              },
              isSubscribed: {
                equals: true,
              },
              ...(projectStaffRole && {
                staffMemberRole: {
                  equals: projectStaffRole,
                },
              }),
            },
          },
        },
      },
    },
  )

  return {
    projects,
    loading,
    refetch,
  }
}

export default useProjectMany
