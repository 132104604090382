import { gql } from '@apollo/client'

import { Task } from 'types/task'

export const CREATE_TASK = gql`
  mutation createTask($data: TaskCreateInput!) {
    createOneTask(data: $data) {
      id
    }
  }
`

export interface CreateTaskPayload {
  createOneTask: {
    id: string
  }
}

export interface CreateTaskVariables {
  data: {
    data: {
      archivedAt?: Date
      completedAt?: Date
      description: string
      dueAt?: Date
      remindAt?: Date
    }
    project: {
      connect: {
        id: string
      }
    }
    owner?: {
      connect: {
        id: string
      }
    }
  }
}

export const DELETE_TASK = gql`
  mutation deleteTask($where: TaskWhereUniqueInput!) {
    deleteOneTask(where: $where) {
      id
    }
  }
`

export interface DeleteTaskPayload {
  deleteOneTask: {
    id: string
  }
}

export interface DeleteTaskVariables {
  where: {
    id: string
  }
}

export const FIND_MANY_TASK = gql`
  query tasks($take: Int, $where: TaskWhereInput!) {
    tasks: findManyTask(take: $take, where: $where) {
      id
      createdAt
      data
      owner {
        id
        firstName
        lastName
        picture
      }
      project {
        id
        owners {
          isSubscribed
          isPrimary
          user {
            id
            auth0Id
            email
            firstName
            lastName
            phone
            picture
            type
          }
          userRole
        }
        staff(orderBy: { createdAt: { sort: asc } }) {
          isSubscribed
          isPrimary
          staffMember {
            id
            auth0Id
            data
            email
            firstName
            lastName
            picture
            type
          }
          staffMemberId
          staffMemberRole
        }
      }
      updatedAt
    }
  }
`

export interface FindManyTaskPayload {
  tasks: Task[]
}

export interface FindManyTaskVariables {
  take?: number
  orderBy?: {
    createdAt?: { sort: string }
  }
  where: {
    project?: {
      id: { in: string[] }
    }
    ownerId?: {
      equals: string
    }
    AND?: {
      createdAt?: {
        gte?: string
        lte?: string
      }
    }[]
  }
}

export const UPDATE_TASK = gql`
  mutation updateTask($data: TaskUpdateInput!, $where: TaskWhereUniqueInput!) {
    updateOneTask(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateTaskPayload {
  updateOneTask: {
    id: string
  }
}

export interface UpdateTaskVariables {
  data: {
    data?: {
      archivedAt?: Date | string | null
      completedAt?: Date | string | null
      description?: string | null
      dueAt?: Date | string | null
      remindAt?: Date | string | null
    }
    owner?: {
      connect?: { id: string }
    }
  }
  where: {
    id: string
  }
}
