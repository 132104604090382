import { FindManyRoomElementClassVariables } from 'queries/room-element-class'

export interface QueryFilters {
  // search by description
  search?: string

  // how many to take
  take: number

  // sort values
  sortBy: 'identifier'
  sortDirection: 'ascending' | 'descending'
}

export const getQueryVariables = ({
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyRoomElementClassVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'identifier': {
        return {
          identifier: { sort: sortDirection === 'descending' ? 'desc' : 'asc' },
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyRoomElementClassVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        identifier: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  return variables
}
