import { FindManyRenderPropVariables } from 'queries/render-prop'

export interface QueryFilters {
  groupIdentifiers?: string[]

  // search by description
  search?: string

  // how many to take
  take: number

  // sort values
  sortBy: 'identifier' | 'groupIdentifier'
  sortDirection: 'ascending' | 'descending'
}

export const getQueryVariables = ({
  groupIdentifiers,
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyRenderPropVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'identifier': {
        return {
          identifier: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      case 'groupIdentifier': {
        return [
          {
            groupIdentifier: {
              sort: sortDirection === 'descending' ? 'desc' : 'asc',
            },
          },
          {
            identifier: 'asc',
          },
        ]
      }
      default: {
        return undefined
      }
    }
  })() as FindManyRenderPropVariables['orderBy']
  let variables: FindManyRenderPropVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        description: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (groupIdentifiers?.length) {
    return {
      ...variables,
      where: {
        ...variables.where,
        groupIdentifier: {
          in: groupIdentifiers,
        },
      },
    }
  }
  return variables
}
