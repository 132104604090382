import { useQuery } from '@apollo/client'
import moment from 'moment'

import {
  FIND_MANY_TASK,
  FindManyTaskPayload,
  FindManyTaskVariables,
} from 'queries/task'

const useTaskMany = (userId: string) => {
  const {
    data: { tasks } = { tasks: [] },
    loading,
    refetch,
  } = useQuery<FindManyTaskPayload, FindManyTaskVariables>(FIND_MANY_TASK, {
    variables: {
      take: 400,
      orderBy: { createdAt: { sort: 'desc' } },
      where: {
        ownerId: { equals: userId },
        AND: [
          {
            createdAt: {
              gte: moment().startOf('year').toISOString(),
              lte: moment().endOf('year').toISOString(),
            },
          },
        ],
      },
    },
  })

  return {
    tasks,
    loading,
    refetch,
  }
}

export default useTaskMany
