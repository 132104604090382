import { gql } from '@apollo/client'

import { GenericFeature } from 'types/generic-feature'

export const FIND_MANY_GENERIC_FEATURE = gql`
  query findManyGenericFeature($where: GenericFeatureWhereInput) {
    genericFeatures: findManyGenericFeature(where: $where) {
      id
      identifier
      description
      options(orderBy: { identifier: { sort: asc } }) {
        id
        identifier
        description
        featureIdentifier
        optionIdentifier
      }
    }
  }
`

export interface FindManyGenericFeaturePayload {
  genericFeatures: GenericFeature[]
}

export interface FindManyGenericFeatureVariables {
  where?: {
    featureNo?: { in: string[] }
  }
}

export const FIND_UNIQUE_GENERIC_FEATURE = gql`
  query findUniqueGenericFeature($where: GenericFeatureWhereUniqueInput!) {
    genericFeature: findUniqueGenericFeature(where: $where) {
      id
      description
      identifier
    }
  }
`

export interface FindUniqueGenericFeaturePayload {
  genericFeature: GenericFeature
}

export interface FindUniqueGenericFeatureVariables {
  where?: {
    id: string
  }
}
