// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Button, Grid, Table } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import ConfirmationModal from 'components/shared/confirmation-modal'
import Loader from 'components/shared/loader'
import useRoomManyNoContext from 'context/room/use-many-no-context'
import useRoomMutateNoContext from 'context/room/use-mutate-no-context'
import { getRoomFormattedType } from 'context/room/utils'
import { RoomGraphQL, RoomType } from 'types/room'

const Rooms = (props: RouteComponentProps) => {
  const { rooms, loading, refetch } = useRoomManyNoContext({
    variables: {
      orderBy: { createdAt: { sort: 'desc' } },
      take: 20,
      where: {
        projectId: null,
      },
    },
  })
  const { deleteRoom, loadingDelete, createRoom } = useRoomMutateNoContext()

  const [roomToDelete, setRoomToDelete] = useState<RoomGraphQL>()

  if (loading) return <Loader />

  return (
    <Grid>
      <SectionTitle
        title="Test Rooms for Planner"
        onClickButton={async () =>
          await createRoom({
            variables: {
              data: {
                type: RoomType.Kitchen,
                designs: {
                  create: [
                    {
                      name: 'Test Design',
                    },
                  ],
                },
              },
            },
            onCompleted: () => refetch(),
          })
        }
        buttonLabel="Create Test Room"
      />
      <Grid.Row>
        <Grid.Column>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Created At</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell># Designs</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rooms.map((r) => (
                <Table.Row key={r.id}>
                  <Table.Cell width={5}>
                    {r.createdAt && moment(r.createdAt).format('MM/DD/YYYY LT')}
                  </Table.Cell>
                  <Table.Cell width={5}>{getRoomFormattedType(r)}</Table.Cell>
                  <Table.Cell width={4}>{r.designs.length}</Table.Cell>
                  <Table.Cell width={2}>
                    <Button
                      primary
                      icon={<FontAwesomeIcon icon={['fal', 'eye']} />}
                      onClick={() => props.navigate(`/admin/rooms/${r.id}`)}
                    />
                    <Button
                      icon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
                      onClick={() => setRoomToDelete(r)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <ConfirmationModal
              firstTitle="Are you sure you want to delete this room?"
              loading={loadingDelete}
              onCancel={() => setRoomToDelete(undefined)}
              onConfirm={async () => {
                await deleteRoom({
                  variables: {
                    where: {
                      id: roomToDelete.id,
                    },
                  },
                })
                setRoomToDelete(undefined)
              }}
              open={!!roomToDelete}
            />
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default (props: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Rooms {...props} />
    </AdminLayout>
  )
}
