import { FindManyProspectVariables } from 'queries/prospect'
import { MeetingOutcome, MeetingTypeProspect } from 'types/meeting'
import { ProspectType } from 'types/prospect'

export interface QueryFilters {
  // staff assigned
  assignedTo?: {
    id: string
    name?: string
  } | null
  meetingType?: MeetingTypeProspect
  meetingOutcome?: MeetingOutcome
  meetingTime?: {
    gte: string
    lte: string
  }
  prospectType?: ProspectType
  suggestedFollowUpDate?: {
    gte: string
    lte: string
  }

  // owner search
  search?: string

  // how many to take
  take: number

  // sort values
  sortBy?: 'derivedMeeting'
  sortDirection?: 'descending' | 'ascending'
}

export const getQueryVariables = ({
  assignedTo,
  meetingType,
  meetingOutcome,
  meetingTime,
  prospectType,
  suggestedFollowUpDate,
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyProspectVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'derivedMeeting': {
        return {
          derivedMeeting: {
            sort: sortDirection === 'descending' ? 'desc' : 'asc',
          },
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyProspectVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (assignedTo?.id) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        staff: {
          some: {
            staffMemberId: {
              equals: assignedTo.id,
            },
            isSubscribed: {
              equals: true,
            },
          },
        },
      },
    }
  }
  if (prospectType) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        data: {
          path: ['prospectType'],
          equals: prospectType,
        },
      },
    }
  }

  if (suggestedFollowUpDate) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        data: {
          path: ['followUpCallDate'],
          gte: suggestedFollowUpDate.gte,
          lte: suggestedFollowUpDate.lte,
        },
      },
    }
  }
  if (meetingType || meetingOutcome || meetingTime) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        meetings: {
          some: {
            ...(meetingTime
              ? {
                  canceledAt: null,
                  startTime: {
                    gte: meetingTime.gte,
                    lte: meetingTime.lte,
                  },
                }
              : undefined),
            AND: [
              ...(meetingOutcome
                ? [
                    {
                      metadata: {
                        path: ['outcome'],
                        equals: meetingOutcome,
                      },
                    },
                  ]
                : []),
              ...(meetingType
                ? [
                    {
                      metadata: {
                        path: ['type'],
                        equals: meetingType,
                      },
                    },
                  ]
                : []),
            ],
            ...(assignedTo
              ? {
                  staffMemberId: {
                    equals: assignedTo.id,
                  },
                }
              : undefined),
          },
        },
      },
    }
  }
  return variables
}
