import { gql } from '@apollo/client'

import { ProjectStaff, ProjectStaffRole } from 'types/project-staff'

export const FIND_MANY_PROJECTS_STAFF = gql`
  query findManyProjectsStaff(
    $orderBy: [ProjectsStaffOrderByWithRelationInput!]
    $take: Int
    $where: ProjectsStaffWhereInput
  ) {
    staff: findManyProjectsStaff(
      take: $take
      orderBy: $orderBy
      where: $where
    ) {
      createdAt
      data
      isSubscribed
      isPrimary
      project {
        id
        createdAt
        data
        owners {
          isSubscribed
          isPrimary
          user {
            id
            email
            firstName
            lastName
            picture
            type
          }
          userRole
        }
        name
      }
      staffMember {
        id
        data
        email
        firstName
        lastName
        picture
        type
      }
      staffMemberId
      staffMemberRole
    }
  }
`

export interface FindManyProjectsStaffPayload {
  staff: ProjectStaff[]
}

export interface FindManyProjectsStaffVariables {
  orderBy?: {
    createdAt: { sort: 'asc' | 'desc' }
  }
  take?: number
  where?: {
    project?: {
      archivedAt: Date
    }
    staffMemberRole?: {
      equals?: ProjectStaffRole
      in?: ProjectStaffRole[]
    }
  }
}

export const CREATE_ONE_PROJECT_STAFF = gql`
  mutation createOneProjectsStaff($data: ProjectsStaffCreateInput!) {
    createOneProjectsStaff(data: $data) {
      projectId
      staffMemberId
    }
  }
`

export interface CreateOneProjectStaffPayload {
  createOneProjectsStaff: ProjectStaff
}

export interface CreateOneProjectStaffVariables {
  data: {
    data?: ProjectStaff['data']
    isSubscribed?: boolean
    isPrimary?: boolean
    staffMemberRole?: ProjectStaffRole
    project: {
      connect: {
        id: string
      }
    }
    staffMember: {
      connect: {
        id: string
      }
    }
  }
}

export const DELETE_ONE_PROJECT_STAFF = gql`
  mutation deleteOneProjectsStaff($where: ProjectsStaffWhereUniqueInput!) {
    deleteOneProjectsStaff(where: $where) {
      projectId
      staffMemberId
    }
  }
`

export interface DeleteOneProjectStaffPayload {
  deleteOneProjectsStaff: {
    projectId: string
    staffMemberId: string
  }
}

export interface DeleteOneProjectStaffVariables {
  where: {
    projectId_staffMemberId: {
      projectId: string
      staffMemberId: string
    }
  }
}

export const UPDATE_ONE_PROJECT_STAFF = gql`
  mutation updateOneProjectsStaff(
    $data: ProjectsStaffUpdateInput!
    $where: ProjectsStaffWhereUniqueInput!
  ) {
    updateOneProjectsStaff(data: $data, where: $where) {
      projectId
      staffMemberId
    }
  }
`

export interface UpdateOneProjectStaffPayload {
  updateOneProjectsStaff: ProjectStaff
}

export interface UpdateOneProjectStaffVariables {
  data: {
    data?: ProjectStaff['data']
    isPrimary?: boolean
    isSubscribed?: boolean
    staffMemberRole?: ProjectStaffRole
    staffMember: {
      connect: {
        id: string
      }
    }
  }
  where: {
    projectId_staffMemberId: {
      projectId: string
      staffMemberId: string
    }
  }
}
