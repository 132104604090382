import { gql } from '@apollo/client'

import { Media, MediaCategory, MediaData } from 'types/media'

export const CREATE_ONE_MEDIA = gql`
  mutation createOneMedia($data: MediaCreateInput!) {
    createOneMedia(data: $data) {
      id
    }
  }
`

export interface CreateMediaPayload {
  createOneMedia: {
    id: string
  }
}

export interface CreateMediaVariables {
  data: {
    category: MediaCategory
    data?: MediaData
    design?: {
      connect: {
        id: string
      }
    }
    key: string
    name: string
    catalogFeatures: {
      connect: { id: string }[]
    }
    genericOptions: {
      connect: { id: string }[]
    }
    genericProductClasses: {
      connect: { id: string }[]
    }
    nobiliaOptions: {
      connect: { id: string }[]
    }
    nobiliaProductClasses: {
      connect: { id: string }[]
    }
    roomElementClasses: {
      connect: { id: string }[]
    }
    roomElementOptions: {
      connect: { id: string }[]
    }
  }
}

export const DELETE_ONE_MEDIA = gql`
  mutation deleteOneMedia($where: MediaWhereUniqueInput!) {
    deleteOneMedia(where: $where) {
      id
    }
  }
`

export interface DeleteMediaPayload {
  deleteOneMedia: {
    id: string
  }
}

export interface DeleteMediaVariables {
  where: {
    id: string
  }
}

export const FIND_MANY_MEDIA = gql`
  query findManyMedia(
    $take: Int
    $orderBy: [MediaOrderByWithRelationInput!]
    $skip: Int
    $where: MediaWhereInput
  ) {
    count: findManyMediaCount(where: $where)
    media: findManyMedia(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      id
      catalogFeatures {
        id
        identifier
      }
      category
      createdAt
      data
      key
      name
      genericOptions {
        id
        identifier
      }
      genericProductClasses {
        id
        identifier
      }
      nobiliaOptions {
        id
        catalog
        description
        featureNo
        identifier
        optionKey
      }
      nobiliaProductClasses {
        id
        catalog
        identifier
        orderId
      }
      roomElementClasses {
        id
        identifier
      }
      roomElementOptions {
        id
        identifier
      }
      updatedAt
    }
  }
`

export interface FindManyMediaPayload {
  count?: number
  media: Media[]
}

export interface FindManyMediaVariables {
  orderBy?: {
    createdAt?: { sort: string }
    name?: { sort: string }
  }
  skip?: number
  take?: number
  where?: {
    category?: { in: string[] }
    name?: {
      contains: string
      mode?: 'insensitive'
    }
  }
}

export const UPDATE_ONE_MEDIA = gql`
  mutation updateOneMedai(
    $data: MediaUpdateInput!
    $where: MediaWhereUniqueInput!
  ) {
    updateOneMedia(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateMediaPayload {
  updateOneMedia: {
    id: string
  }
}

export interface UpdateMediaVariables {
  data: {
    category?: MediaCategory
    catalogFeatures?: {
      set: { id: string }[]
    }
    data?: MediaData
    genericOptions?: {
      set: { id: string }[]
    }
    genericProductClasses?: {
      set: { id: string }[]
    }
    key?: string
    name?: string
    nobiliaOptions?: {
      set: { id: string }[]
    }
    nobiliaProductClasses?: {
      set: { id: string }[]
    }
    roomElementClasses?: {
      set: { id: string }[]
    }
    roomElementOptions?: {
      set: { id: string }[]
    }
  }
  where: {
    id: string
  }
}
