import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { orderBy } from 'lodash'
import moment from 'moment'
import { Button, Grid, Image, List, Table } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import AddressFormat from 'components/shared/address-format'
import Loader from 'components/shared/loader'
import { parseOwners, parseStaff } from 'context/project/utils'
import {
  FIND_MANY_PROJECT_BUILDZOOM,
  FindManyProjectPayload,
  FindManyProjectVariables,
} from 'queries/project'
import { TableUserListItem } from 'styles/admin/main'
import { MeetingTypeProject } from 'types/meeting'
import { BuildZoomStatus, Project } from 'types/project'
import * as viewUtils from 'views/utils'

const ProjectsForBuildZoom = (_: RouteComponentProps) => {
  const { data, loading } = useQuery<
    FindManyProjectPayload,
    FindManyProjectVariables
  >(FIND_MANY_PROJECT_BUILDZOOM, {
    fetchPolicy: 'no-cache',
    variables: {
      orderBy: { createdAt: { sort: 'desc' } },
      where: {
        archivedAt: null,
        OR: Object.keys(BuildZoomStatus).map((key) => ({
          data: {
            path: ['buildZoomStatus'],
            equals: key,
          },
        })),
      },
    },
  })

  if (loading) return <Loader />

  const projects = data?.projects

  return (
    <Grid>
      <SectionTitle title="Projects for BuildZoom" />
      <Grid.Row>
        <Grid.Column>
          <p>{projects?.length} results</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ProjectsTable projects={projects ?? []} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <ProjectsForBuildZoom />
    </AdminLayout>
  )
}

const ProjectsTable = ({ projects }: { projects: Project[] }) => {
  const [state, setState] = useState<'ascending' | 'descending' | null>(null)

  const sortedProjects = !state
    ? projects
    : orderBy(
        projects,
        (p) => p.data.buildZoomStatus,
        state === 'ascending' ? 'asc' : 'desc',
      )

  return (
    <Table className="small" celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Owners</Table.HeaderCell>
          <Table.HeaderCell>Designers</Table.HeaderCell>
          <Table.HeaderCell>Location</Table.HeaderCell>
          <Table.HeaderCell>Project Status</Table.HeaderCell>
          <Table.HeaderCell
            onClick={() => {
              setState(state === 'ascending' ? 'descending' : 'ascending')
            }}
            sorted={state ?? undefined}
          >
            Status
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedProjects.map((p) => {
          return <ProjectTableRow key={p.id} project={p} />
        })}
      </Table.Body>
    </Table>
  )
}

const ProjectTableRow = ({ project }: { project: Project }) => {
  const { buildZoomContractorName, buildZoomStatus } = project.data
  const meeting = project.meetings.find(
    (m) => m.metadata?.type === MeetingTypeProject.BUILDZOOM,
  )

  return (
    <Table.Row>
      <Table.Cell>
        <List relaxed>
          {parseOwners(project).primaryOwners.map((o) => (
            <TableUserListItem key={o.user?.id}>
              <Image src={o.user?.picture} avatar circular />
              <List.Content>
                <List.Header>{viewUtils.getNameForUser(o.user)}</List.Header>
              </List.Content>
            </TableUserListItem>
          ))}
        </List>
      </Table.Cell>
      <Table.Cell>
        <List relaxed>
          {parseStaff(project).allStaff.map((u) => (
            <TableUserListItem key={u.id}>
              <Image src={u.picture} avatar circular />
              <List.Content className="small">
                <List.Header>{viewUtils.getNameForUser(u)}</List.Header>
                <List.Description>
                  {viewUtils.capsSnakeCaseToTitleCase(u.type)}
                </List.Description>
              </List.Content>
            </TableUserListItem>
          ))}
        </List>
      </Table.Cell>
      <Table.Cell>
        <AddressFormat address={project.projectAddress} />
      </Table.Cell>
      <Table.Cell>{viewUtils.getPaymentString(project)}</Table.Cell>
      <Table.Cell>
        {viewUtils.capsSnakeCaseToTitleCase(buildZoomStatus)}
        {buildZoomStatus === BuildZoomStatus.CONTRACTOR_INTRODUCED ? (
          <p className="gray">{buildZoomContractorName}</p>
        ) : (
          <p className="gray">
            {meeting?.startTime &&
              moment(meeting.startTime).format('MM/DD/YYYY hh:mm a')}
          </p>
        )}
      </Table.Cell>
      <Table.Cell textAlign="center" width={1}>
        <Button
          primary
          onClick={() => navigate(`/admin/projects-buildzoom/${project.id}`)}
          icon={<FontAwesomeIcon icon={['fal', 'eye']} />}
        />
      </Table.Cell>
    </Table.Row>
  )
}
