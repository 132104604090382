import { useQuery } from '@apollo/client'

import {
  FIND_MANY_PROJECTS_STAFF,
  FindManyProjectsStaffPayload,
  FindManyProjectsStaffVariables,
} from 'queries/project-staff'
import { ProjectStaffRole } from 'types/project-staff'

const useProjectsStaff = (projectStaffRole: ProjectStaffRole) => {
  const {
    data: { staff } = { staff: [] },
    loading: loadingProjectsStaff,
    refetch: refetchProjectsStaff,
  } = useQuery<FindManyProjectsStaffPayload, FindManyProjectsStaffVariables>(
    FIND_MANY_PROJECTS_STAFF,
    {
      variables: {
        take: 50,
        orderBy: {
          createdAt: { sort: 'desc' },
        },
        where: {
          staffMemberRole: {
            equals: projectStaffRole,
          },
        },
      },
    },
  )

  return { projectsStaff: staff, loadingProjectsStaff, refetchProjectsStaff }
}

export default useProjectsStaff
