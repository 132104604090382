import { useQuery } from '@apollo/client'

import {
  FIND_MANY_CONVERSATION_NO_PROJECT,
  FindManyConversationPayload,
  FindManyConversationVariables,
} from 'queries/conversation'
import { getNameForUser } from 'views/utils'

import useProject from '../use'

export const useConversationMessages = () => {
  const { project } = useProject()

  const { data } = useQuery<
    FindManyConversationPayload,
    FindManyConversationVariables
  >(FIND_MANY_CONVERSATION_NO_PROJECT, {
    variables: {
      where: {
        clientUser: {
          is: {
            projects: {
              some: {
                projectId: {
                  equals: project.id,
                },
              },
            },
          },
        },
      },
    },
  })

  const conversations = data?.conversations ?? []
  const usersPerConversation = conversations.map((conversation) => {
    return {
      conversationId: conversation.id,
      userId: conversation.clientUser?.id,
      userName: getNameForUser(conversation.clientUser),
    }
  })

  return {
    conversations,
    usersPerConversation,
  }
}
