import { gql } from '@apollo/client'

import { ProjectChecklistItemData } from 'types/project-checklist-item'

export const FIND_MANY_PROJECT_CHECKLIST_ITEM = gql`
  query fetchProjectChecklistItem($where: ProjectChecklistItemWhereInput) {
    checklistItems: findManyProjectChecklistItem(where: $where) {
      id
      identifier
      projectId
    }
  }
`

export type FindManyProjectChecklistItemVariables = {
  where: {
    project: {
      is: {
        id: { equals: string }
      }
    }
  }
}

export type FindManyProjectChecklistItemPayload = {
  checklistItems: { id: string; identifier: string; projectId: string }[]
}

export const CREATE_PROJECT_CHECKLIST_ITEM = gql`
  mutation createProjectChecklistItem($data: ProjectChecklistItemCreateInput!) {
    createOneProjectChecklistItem(data: $data) {
      id
      identifier
      projectId
    }
  }
`

export type CreateProjectChecklistItemPayload = {
  createOneProjectChecklistItem: {
    id: string
    identifier: string
    projectId: string
  }
}

export type CreateProjectChecklistItemVariables = {
  data: {
    data?: ProjectChecklistItemData
    identifier: string
    project: { connect: { id: string } }
  }
}

export const DELETE_PROJECT_CHECKLIST_ITEM = gql`
  mutation deleteProjectChecklistItem(
    $where: ProjectChecklistItemWhereUniqueInput!
  ) {
    deleteOneProjectChecklistItem(where: $where) {
      id
    }
  }
`

export type DeleteProjectChecklistItemPayload = {
  deleteOneProjectChecklistItem: {
    id: string
  }
}

export type DeleteProjectChecklistItemVariables = {
  where: {
    id: string
  }
}
