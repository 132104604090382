import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Divider, Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useProject from 'context/project/use'
import useProjectFileManyNoContext from 'context/project-file/use-many-no-context'
import { ProjectFileType } from 'types/project-file'

const CustomImages = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project, refetch: refetchProject } = useProject()
  const { projectFiles: customImages, refetch } = useProjectFileManyNoContext({
    variables: {
      where: {
        project: {
          is: {
            id: {
              equals: project.id,
            },
          },
        },
        type: {
          equals: ProjectFileType.CONCEPT_IMAGE,
        },
      },
    },
  })

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Custom Images" />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h5>Upload custom images</h5>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p>
              Upload any custom images to be used for design brief(s) or
              rendering
            </p>
          </Grid.Column>
        </Grid.Row>
        <ProjectFileUploads
          projectFiles={customImages}
          projectFileType={ProjectFileType.CONCEPT_IMAGE}
          projectId={project.id}
          refetch={async () => {
            refetchProject()
            refetch()
          }}
        />
      </Grid>
    </ProjectLayout>
  )
}

export default CustomImages
