import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import useProject from 'context/project/use'
import {
  FIND_MANY_DELIVERY,
  FindManyDeliveryPayload,
  FindManyDeliveryVariables,
} from 'queries/delivery'
import { Delivery } from 'types/delivery'

interface IProjectDeliveryManyContext {
  deliveries: Delivery[]
  loading: boolean
  refetch: () => Promise<unknown>
}

interface ProjectDeliveryManyProps
  extends RouteComponentProps<{ project_id: string }> {
  children: React.ReactNode
}

export const ProjectDeliveryManyContext =
  createContext<IProjectDeliveryManyContext>({} as IProjectDeliveryManyContext)

const ProjectDeliveryManyProvider = (props: ProjectDeliveryManyProps) => {
  const { project } = useProject()
  const { data, loading, refetch } = useQuery<
    FindManyDeliveryPayload,
    FindManyDeliveryVariables
  >(FIND_MANY_DELIVERY, {
    variables: {
      where: {
        project: {
          is: {
            id: {
              equals: project.id,
            },
          },
        },
      },
    },
  })

  if (loading) return <Loader />

  return (
    <ProjectDeliveryManyContext.Provider
      value={{
        deliveries: data?.deliveries ?? [],
        loading,
        refetch,
      }}
    >
      {props.children}
    </ProjectDeliveryManyContext.Provider>
  )
}

export default ProjectDeliveryManyProvider
