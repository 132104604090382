import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import useProject from 'context/project/use'
import {
  FIND_MANY_SUPPLIER_ORDER,
  FindManySupplierOrderPayload,
  FindManySupplierOrderVariables,
} from 'queries/supplier-order'
import { SupplierOrder } from 'types/supplier-order'

interface IProjectSupplierOrderManyContext {
  supplierOrders: SupplierOrder[]
  loading: boolean
  refetch: () => Promise<unknown>
}

interface ProjectSupplierOrderManyProps
  extends RouteComponentProps<{ project_id: string }> {
  children: React.ReactNode
}

export const ProjectSupplierOrderManyContext =
  createContext<IProjectSupplierOrderManyContext>(
    {} as IProjectSupplierOrderManyContext,
  )

const ProjectSupplierOrderManyProvider = (
  props: ProjectSupplierOrderManyProps,
) => {
  const { project } = useProject()
  const { data, loading, refetch } = useQuery<
    FindManySupplierOrderPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER, {
    variables: {
      where: {
        project: {
          is: {
            id: {
              equals: project.id,
            },
          },
        },
      },
    },
  })

  if (loading) return <Loader />

  return (
    <ProjectSupplierOrderManyContext.Provider
      value={{
        supplierOrders: data?.supplierOrders ?? [],
        loading,
        refetch,
      }}
    >
      {props.children}
    </ProjectSupplierOrderManyContext.Provider>
  )
}

export default ProjectSupplierOrderManyProvider
