import { FindManyRoomVariables } from 'queries/room'
import { ProjectStaffRole } from 'types/project-staff'

export interface QueryFilters {
  // staff assigned
  assignedTo?: {
    id: string
    name?: string
  } | null
  assignedToRole?: ProjectStaffRole | null

  // special queries
  packStatus?: 'all' | 'awaitingPack' | 'releasedPack'

  // owner search
  search?: string

  // how many to take
  take: number | undefined

  // sort values
  sortBy?: 'derivedStatus'
  sortDirection?: 'descending' | 'ascending'
}

export const getQueryVariables = ({
  assignedTo,
  assignedToRole,
  packStatus,
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyRoomVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'derivedStatus': {
        return {
          derivedStatus: {
            sort: sortDirection === 'descending' ? 'desc' : 'asc',
          },
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyRoomVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (assignedTo?.id) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        project: {
          is: {
            staff: {
              some: {
                staffMemberId: {
                  equals: assignedTo.id,
                },
                isSubscribed: {
                  equals: true,
                },
                ...(assignedToRole && {
                  staffMemberRole: {
                    equals: assignedToRole,
                  },
                }),
              },
            },
          },
        },
      },
    }
  }
  if (['awaitingPack', 'releasedPack'].includes(packStatus as string)) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedStatus: {
          contains: packStatus,
        },
      },
    }
  }
  return variables
}
