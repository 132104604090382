import { FindManyIssueVariables } from 'queries/issue'
import { Issue, IssueType, ResolutionType } from 'types/issue'

export interface QueryFilters {
  // staff assigned
  assignedTo?: {
    id: string | undefined
    name?: string
  } | null
  // search string
  search?: string

  //query values
  issueType?: IssueType[]
  resolutionType?: ResolutionType[]

  status?: 'unresolved' | 'resolved' | 'all'

  // how many to take
  take: number | undefined

  // sort values
  sortBy?: 'createdAt' | 'status'
  sortDirection?: 'descending' | 'ascending'
}

export const getQueryVariables = ({
  assignedTo,
  issueType,
  resolutionType,
  search,
  sortBy,
  sortDirection,
  status,
  take,
}: QueryFilters): FindManyIssueVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'createdAt': {
        return {
          createdAt: { sort: sortDirection === 'descending' ? 'desc' : 'asc' },
        }
      }
      case 'status': {
        return {
          derivedStatus: {
            sort: sortDirection === 'descending' ? 'desc' : 'asc',
          },
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyIssueVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (assignedTo?.id) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        project: {
          is: {
            staff: {
              some: {
                staffMemberId: {
                  equals: assignedTo.id,
                },
                isSubscribed: {
                  equals: true,
                },
              },
            },
          },
        },
      },
    }
  }
  if (issueType?.length) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        AND: [
          ...(variables.where?.AND || []),
          {
            OR: issueType.map((s) => ({
              type: {
                equals: s,
              },
            })),
          },
        ],
      },
    }
  }

  if (resolutionType?.length) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        AND: [
          ...(variables.where?.AND || []),
          {
            OR: resolutionType.map((s) => ({
              resolutionType: {
                equals: s,
              },
            })),
          },
        ],
      },
    }
  }

  if (status === 'resolved') {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedStatus: {
          gte: '05',
        },
      },
    }
  }

  if (status === 'unresolved') {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedStatus: {
          lt: '05',
        },
      },
    }
  }

  return variables
}

export const parseIssuesByResolution = (issues: Issue[]) =>
  issues.reduce<{
    addOnCharged: Issue[]
    addOnComplementary: Issue[]
    addOnIssues: Issue[]
    noOrderIssues: Issue[]
    pendingIssues: Issue[]
    remedialIssues: Issue[]
  }>(
    (acc, i) => {
      if (i.resolutionType === ResolutionType.ADD_ON) {
        acc.addOnIssues.push(i)
        if (i.data?.isAddOnComplementary) acc.addOnComplementary.push(i)
        else acc.addOnCharged.push(i)

        return acc
      }
      if (i.resolutionType === ResolutionType.REMEDIAL) {
        acc.remedialIssues.push(i)
        return acc
      }
      if (i.resolutionType === ResolutionType.NO_ORDER) {
        acc.noOrderIssues.push(i)
        return acc
      }
      if (i.resolutionType === ResolutionType.PENDING) {
        acc.pendingIssues.push(i)
        return acc
      }

      return acc
    },
    {
      addOnCharged: [],
      addOnComplementary: [],
      addOnIssues: [],
      noOrderIssues: [],
      pendingIssues: [],
      remedialIssues: [],
    },
  )
