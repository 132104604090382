import { FORMStatus } from 'context/supplier-order/utils-many'
import { FindManyShipmentVariables } from 'queries/shipment'

export interface QueryFilters {
  includeArchived?: boolean
  includeProposed?: boolean

  search?: string
  statusesFORM?: FORMStatus[]
  warehouse?: string

  // sort values
  sortBy?: 'derivedStatus' | 'reference'
  sortDirection?: 'descending' | 'ascending'

  // how many to take
  take: number
}

export const getQueryVariables = ({
  includeArchived,
  includeProposed,
  search,
  sortBy,
  sortDirection,
  statusesFORM,
  take,
  warehouse,
}: QueryFilters): FindManyShipmentVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'derivedStatus': {
        return {
          derivedStatus: {
            sort: sortDirection === 'descending' ? 'desc' : 'asc',
          },
        }
      }
      case 'reference': {
        return {
          reference: { sort: sortDirection === 'descending' ? 'desc' : 'asc' },
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyShipmentVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }

  variables = {
    ...variables,
    where: {
      ...variables.where,
      archivedAt: null,
      confirmedAt: {
        not: null,
      },
    },
  }

  if (includeProposed) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        confirmedAt: undefined,
      },
    }
  }

  if (includeArchived) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        archivedAt: undefined,
      },
    }
  }

  if (warehouse) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        data: {
          path: ['receivingWarehouse'],
          equals: warehouse,
        },
      },
    }
  }

  if (statusesFORM?.length) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        OR: statusesFORM.map((s) => ({
          derivedStatus: {
            startsWith: s.slice(0, 2),
          },
        })),
      },
    }
  }

  return variables
}
