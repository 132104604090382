import { gql } from '@apollo/client'

import { ProjectStaffRole } from 'types/project-staff'
import { SupplierOrder, SupplierOrderData } from 'types/supplier-order'
import { SupplierOrderActionData } from 'types/supplier-order-action'

// Fragment for supplier order context
export const SUPPLIER_ORDER_CONTEXT_FRAGMENT = gql`
  fragment SupplierOrderContext on SupplierOrder {
    id
    ackFile {
      id
      key
      name
      numPages
      type
    }
    actions {
      id
      ackFile {
        id
        key
        name
        numPages
        type
      }
      data
    }
    createdAt
    data
    issuesResolved {
      id
      type
      resolutionType
      data
      files {
        id
        createdAt
        key
        name
        numPages
        type
      }
      supplierOrderResolving {
        id
        nobiliaOrderRef
      }
    }
    delivery {
      id
      data
      date
    }
    deliveryNote {
      id
      key
      name
      numPages
      type
    }
    design {
      id
      metadata
      name
      order {
        id
        metadata
        placedAt
        targetDate
      }
      plans {
        id
        approvedAt
        createdAt
      }
      room {
        id
        data
        type
      }
    }
    files {
      id
      key
      name
      numPages
      type
    }
    nobiliaAckNumber
    nobiliaOrderRef
    project {
      id
    }
    route {
      id
      archivedAt
      color
      confirmedAt
      data
      reference
    }
    shipment {
      id
      archivedAt
      color
      confirmedAt
      data
      reference
    }
  }
`

// Fragment for supplier order tables
export const SUPPLIER_ORDER_FIND_MANY_FRAGMENT = gql`
  fragment SupplierOrderFindMany on SupplierOrder {
    ...SupplierOrderContext
    project {
      id
      archivedAt
      data
      owners {
        isSubscribed
        isPrimary
        user {
          id
          email
          firstName
          lastName
          picture
          type
        }
        userRole
      }
      projectAddress
      staff(orderBy: { createdAt: { sort: asc } }) {
        isSubscribed
        isPrimary
        staffMember {
          id
          auth0Id
          data
          email
          firstName
          lastName
          picture
          type
        }
        staffMemberId
        staffMemberRole
      }
    }
  }
`

// Fragment for create update modals out of context
export const SUPPLIER_ORDER_LOOKUP_FRAGMENT = gql`
  fragment SupplierOrderLookup on SupplierOrder {
    id
    project {
      id
    }
  }
`

export const CREATE_SUPPLIER_ORDER = gql`
  mutation createSupplierOrder($data: SupplierOrderCreateInput!) {
    createOneSupplierOrder(data: $data) {
      id
    }
  }
`

export interface CreateSupplierOrderPayload {
  createOneSupplierOrder: { id: string }
}

export interface CreateSupplierOrderVariables {
  data: {
    ackFile?: {
      connect: { id: string }
    }
    actions?: {
      create: { data: SupplierOrderActionData }[]
    }
    data: NullableRecord<SupplierOrderData> | null | undefined
    deliveryNote?: {
      connect: { id: string }
    }
    design?: {
      connect: { id: string }
    }
    issuesResolved?: {
      connect: { id: string }[]
    }
    nobiliaAckNumber?: string | null
    nobiliaOrderRef?: string | null
    project: {
      connect: { id: string }
    }
  }
}
export const DELETE_SUPPLIER_ORDER = gql`
  mutation deleteSupplierOrder($where: SupplierOrderWhereUniqueInput!) {
    deleteOneSupplierOrder(where: $where) {
      id
    }
  }
`

export interface DeleteSupplierOrderPayload {
  deleteOneSupplierOrder: SupplierOrder
}

export interface DeleteSupplierOrderVariables {
  where: { id: string }
}

export const FIND_UNIQUE_SUPPLIER_ORDER_CONTEXT = gql`
  query findUniqueSupplierOrder($where: SupplierOrderWhereUniqueInput!) {
    supplierOrder: findUniqueSupplierOrder(where: $where) {
      ...SupplierOrderContext
    }
  }
  ${SUPPLIER_ORDER_CONTEXT_FRAGMENT}
`

export const FIND_UNIQUE_SUPPLIER_ORDER_LOOKUP = gql`
  query findUniqueSupplierOrderLookup($where: SupplierOrderWhereUniqueInput!) {
    supplierOrder: findUniqueSupplierOrder(where: $where) {
      ...SupplierOrderLookup
    }
  }
  ${SUPPLIER_ORDER_LOOKUP_FRAGMENT}
`

export interface FindUniqueSupplierOrderPayload {
  supplierOrder: SupplierOrder
}

export interface FindUniqueSupplierOrderVariables {
  where: {
    id: string
  }
}

export const FIND_MANY_SUPPLIER_ORDER = gql`
  query findManySupplierOrder(
    $take: Int
    $orderBy: [SupplierOrderOrderByWithRelationInput!]
    $skip: Int
    $where: SupplierOrderWhereInput
  ) {
    count: findManySupplierOrderCount(where: $where)
    supplierOrders: findManySupplierOrder(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      ...SupplierOrderFindMany
    }
  }
  ${SUPPLIER_ORDER_FIND_MANY_FRAGMENT}
  ${SUPPLIER_ORDER_CONTEXT_FRAGMENT}
`

export const FIND_MANY_SUPPLIER_ORDER_COUNT = gql`
  query findManySupplierOrderCount($where: SupplierOrderWhereInput) {
    count: findManySupplierOrderCount(where: $where)
  }
`

export interface FindManySupplierOrderCountPayload {
  count: number
}

export interface FindManySupplierOrderPayload {
  supplierOrders: SupplierOrder[]
  count?: number
}

export interface FindManySupplierOrderVariables {
  orderBy?: {
    derivedSearch?: { sort: string }
    derivedStatus?: { sort: string }
    derivedStatusPlanning?: { sort: string }
  }
  skip?: number
  take?: number
  where?: {
    AND?: {
      OR?: {
        design?: {
          is?: {
            name?: {
              startsWith?: string
              not?: {
                startsWith?: string
              }
            }
          }
        }
        derivedStatus?: {
          startsWith?: string
        }
        data?: {
          path: string[]
          equals?: string | boolean
          array_contains?: string[]
        }
        issuesResolved?: {
          none: any
        }
      }[]
    }[]
    createdAt?: {
      gte?: string
      lte?: string
    }
    derivedSearch?: {
      contains?: string
      mode?: 'insensitive'
    }
    derivedStatus?: {
      gte?: string
      lte?: string
      lt?: string
      gt?: string
      startsWith?: string
    }
    derivedStatusPlanning?: {
      contains?: string
    }
    project?: {
      is: {
        id?: {
          equals?: string
        }
        staff?: {
          some?: {
            staffMemberId?: {
              equals: string
            }
            isSubscribed?: {
              equals: boolean
            }
            staffMemberRole?: {
              equals: ProjectStaffRole
            }
          }
        }
      }
    }
    projectId?: {
      not: null
    }
    route?: {
      id?: {
        equals?: string
        in?: string[]
      }
      reference?: {
        in: string[]
      }
    }
    shipment?: {
      is?: {
        id?: {
          equals?: string
          in?: string[]
        }
        reference?: {
          in: string[]
        }
      }
    }
  }
}

export const UPDATE_SUPPLIER_ORDER = gql`
  mutation updateSupplierOrder(
    $data: SupplierOrderUpdateInput!
    $where: SupplierOrderWhereUniqueInput!
  ) {
    updateOneSupplierOrder(data: $data, where: $where) {
      id
      ackFile {
        id
        key
        name
        numPages
        type
      }
      actions {
        id
        ackFile {
          id
          key
          name
          numPages
          type
        }
        data
      }
      createdAt
      data
      deliveryNote {
        id
        key
        name
        numPages
        type
      }
      derivedSearch
      derivedStatus
      design {
        id
        metadata
        name
        order {
          id
          metadata
          placedAt
          targetDate
        }
        plans {
          id
          approvedAt
          createdAt
        }
        room {
          id
          data
          type
        }
      }
      nobiliaAckNumber
      nobiliaOrderRef
      project {
        id
        archivedAt
        data
        owners {
          isSubscribed
          isPrimary
          user {
            id
            email
            firstName
            lastName
            picture
            type
          }
          userRole
        }
        projectAddress
      }
      route {
        id
        archivedAt
        data
        reference
      }
      shipment {
        id
        archivedAt
        color
        confirmedAt
        data
        reference
      }
    }
  }
`

export interface UpdateSupplierOrderPayload {
  updateOneSupplierOrder: { id: string }
}

export interface UpdateSupplierOrderVariables {
  where: { id: string }
  data: {
    ackFile?: {
      connect: { id: string }
    }
    data?: NullableRecord<SupplierOrderData> | null
    deliveryNote?: {
      connect: { id: string }
    }
    design?: {
      connect: { id: string }
    }
    nobiliaAckNumber?: string | null
    nobiliaOrderRef?: string | null
    route?: {
      connect?: { id: string }
      disconnect?: boolean
    }
    shipment?: {
      connect?: { id: string }
      disconnect?: boolean
    }
  }
}

export const UPDATE_MANY_SUPPLIER_ORDER = gql`
  mutation updateManySupplierOrder(
    $data: SupplierOrderUpdateManyMutationInput!
    $where: SupplierOrderWhereInput
  ) {
    updateManySupplierOrder(data: $data, where: $where) {
      count
    }
  }
`

export interface UpdateManySupplierOrderPayload {
  updateManySupplierOrder: {
    count: number
  }
}

export interface UpdateManySupplierOrderVariables {
  data: {
    data?: Partial<SupplierOrderData>
  }
  where: {
    id: {
      in: string[]
    }
  }
}
