import { FindManyIssueVariables } from 'queries/issue'

export interface QueryFilters {
  projectId: string
  sortBy?: 'derivedStatus'
  sortDirection?: 'descending' | 'ascending'
}

export const getQueryVariables = ({
  projectId,
  sortBy,
  sortDirection,
}: QueryFilters): FindManyIssueVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'derivedStatus': {
        return {
          derivedStatus: {
            sort: sortDirection === 'descending' ? 'desc' : 'asc',
          },
        }
      }
      default: {
        return undefined
      }
    }
  })()
  const variables: FindManyIssueVariables = {
    orderBy,
    where: {
      project: {
        is: {
          id: {
            equals: projectId,
          },
        },
      },
    },
  }
  return variables
}
