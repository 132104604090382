import { FindManyCompanyVariables } from 'queries/company'
import { IndustryType } from 'types/company'

export interface QueryFilters {
  companyType?: IndustryType
  search?: string

  // sort values
  sortBy?: 'name'
  sortDirection?: 'ascending' | 'descending'

  // how many to take
  take: number
}

export const getQueryVariables = ({
  companyType,
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyCompanyVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'name': {
        return {
          name: { sort: sortDirection === 'descending' ? 'desc' : 'asc' },
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyCompanyVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (companyType) {
    return {
      ...variables,
      where: {
        ...variables.where,
        data: {
          path: 'industry',
          string_contains: companyType,
        },
      },
    }
  }
  return variables
}
