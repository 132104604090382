import { gql } from '@apollo/client'

import { DisconnectedFeature } from 'types/disconnected-feature'

export const FIND_MANY_DISCONNECTED_FEATURE = gql`
  query findManyDisconnectedFeature($where: DisconnectedFeatureWhereInput) {
    disconnectedFeatures: findManyDisconnectedFeature(where: $where) {
      id
      identifier
      description
      options(orderBy: { identifier: { sort: asc } }) {
        id
        identifier
        description
        featureIdentifier
        optionIdentifier
      }
    }
  }
`

export interface FindManyDisconnectedFeaturePayload {
  disconnectedFeatures: DisconnectedFeature[]
}

export interface FindManyDisconnectedFeatureVariables {
  where?: {
    featureNo?: { in: string[] }
  }
}

export const FIND_UNIQUE_DISCONNECTED_FEATURE = gql`
  query findUniqueDisconnectedFeature(
    $where: DisconnectedFeatureWhereUniqueInput!
  ) {
    disconnectedFeature: findUniqueDisconnectedFeature(where: $where) {
      id
      description
      identifier
    }
  }
`

export interface FindUniqueDisconnectedFeaturePayload {
  disconnectedFeature: DisconnectedFeature
}

export interface FindUniqueDisconnectedFeatureVariables {
  where?: {
    id: string
  }
}
