import { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import moment from 'moment'

import { isMeetingCanceled } from 'context/meeting/utils'
import {
  FIND_MANY_MEETING,
  FindManyMeetingPayload,
  FindManyMeetingVariables,
} from 'queries/meeting'

const now = new Date()

const useMeetingMany = (userId: string) => {
  const {
    data: { meetings } = { meetings: [] },
    loading,
    refetch,
  } = useQuery<FindManyMeetingPayload, FindManyMeetingVariables>(
    FIND_MANY_MEETING,
    {
      variables: {
        take: 30,
        orderBy: { startTime: { sort: 'asc' } },
        where: {
          staffMemberId: {
            equals: userId,
          },
          startTime: {
            gte: moment(now).startOf('day').toISOString(),
          },
        },
      },
    },
  )

  const meetingsNotCanceled = useMemo(
    () => meetings.filter((m) => !isMeetingCanceled(m)),
    [meetings],
  )

  return {
    meetings: meetingsNotCanceled,
    loading,
    refetch,
  }
}

export default useMeetingMany
